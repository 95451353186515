import React, { useState } from 'react'
import Layout from '../../Layout/Layout'
import PageAddress from '../../Component/Common/PageAddress'
import { partnerBanner, sales_para1, sales_para2, sales_para3, sales_para4, sales_para5 } from '../../Data'
import { Link } from 'react-router-dom'
import RatingsContainer from '../../Component/RatingsContainer'
import { useGetAllSalesQuery } from '../../store/storeApi'
import ListInfoContainer2 from '../../Component/TextAndImageContainer/ListInfoContainer2'
import ListInfoContainer from '../../Component/TextAndImageContainer/ListInfoContainer'
import BuySearchFilter from '../../Component/BuyAndRent/BuySearchFilter'
import Property from '../../Component/PropertyDetails/Property'
import { Helmet } from 'react-helmet'

const Sales = () => {
  const [showBuyFilter, setShowBuyFilter] = useState(true)
  const {data:salesProperty} = useGetAllSalesQuery()


  return (
    <div>
      <Layout>
      <Helmet>
  <meta property="og:title" content="Premier Lettings Agency: Expert Services for Property Owners" />
  <meta property="og:description" content="Looking for a reliable lettings agency? Our services cater to property owners seeking professional management and tenant placement." />
  <meta property="og:image" content="https://danhamz.co.uk/images/Lettings.webp" />
  <meta property="og:site_name" content="Danhamz" />
  <meta property="og:url" content={window.location.href} />
  <meta property="og:type" content="article" />
  {/* <!-- Twitter Card Tags --> */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@Danhamz" />
  <meta name="twitter:creator" content="@Danhamz" />
  <meta name="twitter:title" content="Premier Lettings Agency: Expert Services for Property Owners" />
  <meta name="twitter:description" content="Looking for a reliable lettings agency? Our services cater to property owners seeking professional management and tenant placement." />
  <meta name="twitter:image" content="https://danhamz.co.uk/images/Lettings.webp" />
</Helmet>
      <PageAddress main="Home" mainLink="/" category="Sales" />
      <figure className='w-full relative'>
        <h1 className='text-[4.5vw] absolute top-[2vw] italic left-3vw xl:text-3vw lg:text-3vw md:text-3vw text-center text-white'>Selling your home with danhamz</h1>
        <img src="images/Sales_image.jpg" alt="remote" className='w-full h-[40vw] md:h-full md:object-cover' />
        <div className="absolute flex items-center bottom-[5vw] left-[2vw]">
          <Link className='' to={'/book-valuation'}>
          <button className='text-[2.5vw] md:text-vw bg-amber-500 w-[25vw] md:w-[10vw] m-[1vw] rounded-[0.7vw] p-[0.8vw] text-white hover:bg-amber-600 cursor-pointer hover:shadow-md text-center'>Book valuation</button>
          </Link>
          <Link to='/contact'>
          <button className='text-[2.5vw] md:text-vw bg-bl w-[25vw] md:w-[10vw] hover:font-medium m-[1vw] rounded-[0.7vw] p-[0.8vw] text-white cursor-pointer hover:shadow-md text-center'>Contact Us</button>
          </Link>
        </div>
      </figure>
     <main className="grid grid-cols-1 w-full xl:grid-cols-2 place-items-start place-content-around lg:grid-cols-2 md:grid-cols-2 gap-vw bg-gray-100 p-2vw">
        <article className='flex flex-col mt-[4vw] justify-center items-start w-full'>
          {showBuyFilter && <BuySearchFilter />}
        </article>
     <figure className='w-full px-[4vw] md:px-0 xl:max-w-[40vw] lg:max-w-[40vw] md:max-w-[40vw] mt-4vw xl:mt-4vw ml-0 xl:ml-4vw lg:ml-4vw md:ml-3vw h-[30vw]'>
      <img src="images/sales2.jpg" alt="remote" className='w-full object-cover h-[45vw] md:h-[30vw]' />
     </figure>
     </main>
      <article className="w-full pt-[15vw] md:pt-0 bg-[#fff]">
        {sales_para1?.map((item, index) => (
          <ListInfoContainer2 key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
        <div className="flex flex-col justify-center bg-gray-200 items-center w-full p-3vw">
            <h1 className='text-yellow-600 text-[4.5vw] xl:text-2vw lg:text-2vw md:text-2vw font-medium capitalize'>Latest Properties For Sale</h1>
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 gap-2 mt-2vw w-full">
              {salesProperty?.slice(0, 8)?.map((item, index) => (
             <Link to={`/sales-details/${item?._id}`} key={index} className='w-full'>
             <Property key={index} tag={item?.tag} icon={item?.icon} quantity={item?.bedrooms} price={item?.price} name={item?.propertyName} icon2={item?.pics?.[0]?.icon} bed_rooms={item?.bedrooms} img={item?.image1?.url || `${process.env.REACT_APP_API_URL}/sales/${item?.image1?.fileName}`} />
             </Link>
              ))}
            </div>
          </div>
          <article className="w-full bg-[#fff]">
        {sales_para2?.map((item, index) => (
          <ListInfoContainer key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
            <section className='col-center bg-white p-3vw'>
            <h1 className='text-yellow-600 text-[4.5vw] xl:text-2vw lg:text-2vw md:text-2vw font-semibold'>Merketing partners</h1>
            <div className="flex mt-2vw justify-evenly items-center">
            {partnerBanner?.map((item, index) => (
                <img key={index} loading='lazy' src={item?.img} alt={index} className='w-[12vw] xl:w-8vw lg:w-8vw md:w-8vw object-cover ml-4vw'/>
            ))}
            </div>
        </section>
        <article className="w-full bg-[#fff]">
        {sales_para3?.map((item, index) => (
          <ListInfoContainer2 key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
      <article className="w-full bg-[#fff]">
        {sales_para4?.map((item, index) => (
          <ListInfoContainer key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
      <article className="w-full bg-[#fff]">
        {sales_para5?.map((item, index) => (
          <ListInfoContainer2 key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
      <article className='w-full'>
        <RatingsContainer />
      </article>
      </Layout>
    </div>
  )
}

export default Sales