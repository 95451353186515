import React from "react";

const ReportCard = ({ icon, title, onMainCategoryClick }) => {
  return (
    <figure
      onClick={onMainCategoryClick}
      className="w-full md:max-w-[12vw] max-w-[35vw] h-[25vw] md:h-[15vw] transition-transform duration-300 transform hover:scale-105"
    >
      <div className="w-full  cursor-pointer hover:bg-[white]/40 h-[15vh] md:h-[10vw] p-[2vw] my-[8vw] md:m-[1vw] border-[1px] hover:border-[#152347] hover:shadow-sm border-black flex flex-col items-center justify-center shadow-md transition-shadow duration-300">
        <div className="w-full md:max-w-[3.5vw] max-w-[12vw]">
          <img src={icon} alt="" className="w-full" />
        </div>
        <p className="md:text-[0.7vw] text-[2.5vw] mt-[1vw] hover:text-[#152347] hover:font-semibold font-medium text-gray-600 italic text-center md:mt-[0.5vw]">
          {title}
        </p>
      </div>
    </figure>
  );
};

export default ReportCard;
