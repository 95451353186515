import React from 'react'
import Layout from '../Layout/Layout'
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { report_article, report_nav } from '../Data';
import PageAddress from '../Component/Common/PageAddress'

const Report = () => {
  return (
    <div>
        <Layout>
        <main>
      <PageAddress main={'Home'} mainLink={'/'} category={'Report a repair'} subLink={'/report'} />
        {/* time-format */}
        <section className=''>
                {report_nav?.map((item, index) => (
                    <main key={index} className='w-full'>
                    <div style={{ backgroundImage: `url('/img/wooden-background.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }} className="relative md:h-[25vw] flex items-center justify-between w-full p-5vw md:p-3vw" key={index}>
                        <div className=" w-full max-w-[50vw] md:max-w-[30vw]">
                            <h1 className='text-white italic leading-[5vw] md:leading-[2.5vw] text-[5.5vw] md:text-[2.5vw] font-medium w-full md:max-w-[30vw]'>{item?.title}</h1>
                            <p className='text-amber-500 text-4vw md:text-[2vw] italic mt-[1.5vw] font-semibold w-full md:max-w-[30vw]'>{item?.info}</p>
                            <Link to={'/maintenance-request'} className='bg-amber-500 block w-fit mt-[1vw] hover:shadow-md hover:bg-amber-400 p-[0.7vw] rounded-md md:p-[0.5vw]'>
                              <p className='text-[#152347] text-3vw md:text-[1vw] text-center font-medium w-full'>Report Repair</p>
                            </Link>
                        </div>
                        <img src={'/img/refurb_clock1.png'} alt="about" className="w-full mt-vw h-[35vw] md:h-25vw max-w-[35vw]" />
                    </div>
                    <section className='w-full col-center bg-[#ffff]'>
                    <article className="w-full  p-3vw flex flex-col md:flex-row max-w-[80vw] justify-between items-center">
                        <h1 className='text-[#152347] text-[3.5vw] md:text-[1.5vw] font-semibold mt-vw w-full md:max-w-[35vw]'>{item?.info2}</h1>
                       <div className="">
                       <p className='text-gray-700 text-[2.5vw] md:text-vw font-semibold mt-2vw md:mt-vw w-full md:max-w-[35vw]'>{item?.para}</p>
                        <p className='text-gray-700 text-[2.5vw] md:text-vw font-semibold mt-2vw md:mt-vw w-full md:max-w-[35vw]'>{item?.para2}</p>
                        <p className='text-gray-700 text-[2.5vw] md:text-vw font-semibold mt-2vw md:mt-vw w-full md:max-w-[35vw]'>{item?.para3}</p>
                       </div>
                    </article>
                    </section>
                    </main>
                ))}
        </section>
        {/* banner */}
        <article className="w-full col-center p-3vw bg-gray-100">
            <h1 className='mt-2vw md:mt-vw text-[#152347] text-[3.4vw] md:text-[1.5vw] font-medium w-full max-w-[80vw] md:max-w-[60vw]'>If you need to report a repair, maintenance issue, or emergency, please log it now. Utilize the buttons provided below to access the system. 
            </h1>
           <section className='flex mt-vw items-center'>
            <Link to={'/maintenance-request'} className='bg-amber-500 hover:shadow-md hover:bg-amber-400 p-[0.7vw] rounded-md md:p-[0.5vw]'>
                <p className='text-[#152347] text-3vw md:text-[1vw] text-center font-medium w-full'>Report Repair</p>
            </Link>
           </section>
        </article>
        {/* commitment */}
        <article className="w-full col-center p-5vw md:p-3vw bg-[#ffff]">
          <div className="flex flex-col md:flex-row items-center justify-center md:items-start md:justify-between w-full max-w-[85vw]">
            {report_article?.map((item, index) => (
              <section className="w-full md:max-w-[40vw]" key={index}>
                <h1 className='text-[#152347] text-[3.5vw] md:text-[1.5vw] font-semibold mt-2vw md:mt-vw w-full md:max-w-[35vw]'>{item?.title}</h1>
                <p className='text-gray-700 text-3vw md:text-vw mt-2vw md:mt-vw w-full md:max-w-[38vw]'>{item?.info}</p>
               {item?.img && <img src={item?.img} alt="" className='w-full mt-3vw md:mt-vw' />}
               <p className='text-gray-700 text-3vw md:text-vw mt-2vw md:mt-vw w-full md:max-w-[38vw]'>{item?.info2}</p>
              </section>
            ))}
          </div>
        </article>
        </main>
        </Layout>
    </div>
  )
}

export default Report