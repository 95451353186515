import { useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useGlobalContext } from "../../UserContext/UserContext";
import { Toaster, toast } from "sonner";
import { Controller, useForm } from "react-hook-form";
import { reportDataForm } from "../../Data";
import axios from "axios";
import Loader from "./Loader";
import Popup from "../../Component/Popup";
import { useNavigate } from "react-router-dom";


function UploadImages() {
  const [files, setFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const { setmaintenanceDetails } = useGlobalContext();
  const ref = useRef();
  function handleInput() {
    ref.current.click();
  }

  function handleImage(e) {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setmaintenanceDetails((prevmaintenanceDetails) => ({
      ...prevmaintenanceDetails,
      images: [...prevmaintenanceDetails.images, ...newFiles],
    }));

    if (newFiles.length > 0) {
      newFiles.forEach((file) => {
        const url = URL.createObjectURL(file);
        setImageUrl((prevUrls) => [...prevUrls, url]);
      });
    }
  }

  return (
    <section className="w-full ml-[4vw] mt-[4vw]">
      <Toaster position="top-center" />
      <h1 className="text-[2vw] md:text-[0.9vw] w-full max-w-[70vw] text-gray-600">
        Please add up to 10 photos, video, or audio files (max: 30mb each) to
        help explain the problem.
      </h1>
      <section className="flex items-start">
        <div
          onClick={handleInput}
          className="bg-white shadow-md rounded-md w-full max-w-[22vw] md:max-w-[12vw] p-[2vw] flex justify-center hover:cursor-pointer "
        >
          <Icon
            icon="clarity:upload-cloud-line"
            className="text-gray-700 text-[7vw] md:text-[4vw]"
          />
          <input type="file" ref={ref} hidden onChange={handleImage} multiple />
        </div>
        <div className="grid grid-cols-2 gap-3 md:grid-cols-4 items-start">
          {files.map((file, index) => (
            <figure
              key={index}
              className="shadow-md relative rounded-md md:w-full w-[22vw] md:max-w-[12vw] h-[10vw] md:h-[7vw] m-[1vw] overflow-hidden"
            >
              <Icon
                icon="clarity:close-line"
                className="absolute top-0 text-[4.3vw] md:text-[1.3vw] bg-gray-600 hover:bg-gray-700 hover:text-white right-0 p-1 hover:cursor-pointer"
                onClick={() => setFiles(files.filter((_, i) => i !== index))}
              />
              <img
                src={URL.createObjectURL(file)}
                alt={`Image ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </figure>
          ))}
        </div>
      </section>
    </section>
  );
}

export const Form = () => {
  const navigate = useNavigate();
  const { setCurrentStep, maintenanceDetails, setmaintenanceDetails } =
    useGlobalContext();
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const { handleSubmit, control, setValue,  formState: { errors },  reset,} = useForm({
    defaultValues: { address: "", town: "", postalCode: "", name: "", phoneNumber: "", email: "", message: "",},
  });
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    setmaintenanceDetails({ ...maintenanceDetails, [name]: value });
  };
  const [showImage, setShowImage] = useState(true);

  const handleButtonClick = () => {
    if (maintenanceDetails?.images?.length === 0) {
      setDisableNextBtn(true);
      toast.error("Please select images first!");
    } else {
      setShowImage(!showImage);
      setDisableNextBtn(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("mainFault", maintenanceDetails?.mainFault);
      formData.append("childFault", maintenanceDetails?.childFault);
      formData.append("address", maintenanceDetails?.address);
      formData.append("town", maintenanceDetails?.town);
      formData.append("postalCode", maintenanceDetails?.postalCode);
      formData.append("name", maintenanceDetails?.name);
      formData.append("phoneNumber", maintenanceDetails?.phoneNumber);
      formData.append("email", maintenanceDetails?.email);
      formData.append("message", maintenanceDetails?.message);
      formData.append("desc", maintenanceDetails?.desc);
      formData.append("fault", maintenanceDetails?.fault);
      formData.append("subChildFault", maintenanceDetails?.subChildFault);
      maintenanceDetails?.images.forEach((imageFile) => {
        formData.append("images", imageFile);
      });
      setLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendFault`,
        formData
      );
      if (resp.status === 200) {
        setLoading(false);
        toast.success("Report submitted", {
          position: "top-center",
          duration: 2000,
        });
        setmaintenanceDetails({
          mainFault: "",
          childFault: "",
          subChildFault: "",
          fault: "",
          desc: "",
          images: [],
        });
        navigate("/maintenance-request");
        setShowPopup(true);
      } else {
        setLoading(false);
        toast.error("Something went wrong", {
          position: "top-center",
          duration: 2000,
        });
      }
      reset();
    } catch (err) {
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 2000,
      });
    }
  };

  return (
    <main className="w-full h-full relative">
      {disableNextBtn && <Toaster />}
      {showImage ? (
        <div className="max-h-[90vw] min-h-[50vw] md:min-h-[20vw] w-full">
          <UploadImages />
        </div>
      ) : loading ? (
        <Loader />
      ) : showPopup ? (
        <div className="flex justify-center mt-[4vw] items-center">
          <Popup />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full ml-[2vw] md:ml-[10vw]  p-[5vw] text-black "
        >
          <div className="w-full md:gap-2 gap-[10vw] ">
            {reportDataForm?.map((item, index) => (
              <div
                key={index}
                className="p-vw flex flex-col items-start max-w-[80vw] md:max-w-[60vw] relative"
              >
                <label
                  htmlFor=""
                  className="md:text-vw text-[2vw] font-medium ml-[8vw] mb-[0.3vw]"
                >
                  {item?.label}
                </label>
                {/* <br /> */}
                {item?.type === "text" ||
                item?.type === "number" ||
                item?.type === "email" ? (
                  <>
                    <Controller
                      name={item?.name}
                      control={control}
                      rules={item?.rules}
                      render={({ field }) => (
                        <input
                          {...field}
                          onChange={handleInputChange}
                          value={field.value}
                          className="md:text-vw text-[3vw] md:p-0.5vw p-[1.5vw] border-[1px] ml-auto focus:outline-none w-full  focus:shadow-md -mt-[] max-w-[70vw] md:max-w-[50vw]
                          rounded-md spin-button-none"
                          type={item?.type}
                          placeholder={item?.placeholder}
                        />
                      )}
                    />
                  </>
                ) : item?.type === "select" ? (
                  <>
                    <Controller
                      name={item?.name}
                      control={control}
                      rules={item?.rules}
                      render={({ field }) => (
                        <select
                          className="text-vw p-[0.5vw] border-[1px] w-full max-w-[30vw] focus:outline-none focus:shadow-md rounded-md"
                          {...field}
                          onChange={handleInputChange}
                        >
                          <option className="text-vw" value="">
                            Select the option
                          </option>
                          {item?.options?.map((option, index) => (
                            <option
                              className="text-vw"
                              key={index}
                              value={option?.value}
                            >
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </>
                ) : item?.type === "textarea" ? (
                  <>
                    <Controller
                      name={item?.name}
                      control={control}
                      rules={item?.rules}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          onChange={handleInputChange}
                          className="md:text-vw text-[3vw] ml-auto md:p-0.5vw p-[1.5vw] border-[1px] focus:outline-none w-full focus:shadow-md -mt-[] max-w-[70vw] md:max-w-[50vw] rounded-md spin-button-none"
                          type={item?.type}
                          rows={5}
                          placeholder={item?.placeholder}
                        ></textarea>
                      )}
                    />
                  </>
                ) : item?.type === "date" ? (
                  <>
                    <Controller
                      name={item?.name}
                      control={control}
                      rules={item?.rules}
                      render={({ field }) => (
                        <input
                          {...field}
                          onChange={handleInputChange}
                          className="text-vw md:p-0.5vw p-[1.5vw] border-[1px] focus:outline-none w-full focus:shadow-md max-w-[30vw] rounded-md spin-button-none"
                          type={item?.type}
                          placeholder={item?.placeholder}
                        />
                      )}
                    />
                  </>
                ) : null}
                <div>
                  {errors[item?.name] && (
                    <p className="text-red-500 text-2vw md:text-vw ml-8vw mt-[0.5vw]">
                      {errors[item?.name]?.message}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className=" ml-[25vw]  md:ml-[20vw]">
            <button
              type="submit"
              className="md:text-vw text-[3vw] md:p-0.5vw p-[1.5vw] border-[1px] cursor-pointer bg-amber-600 text-white hover:bg-amber-500 focus:outline-none w-full focus:shadow-md max-w-[30vw] rounded-md spin-button-none mt-[4vw]"
            >
              Submit
            </button>
          </div>
        </form>
      )}
      {showImage && (
        <div className="w-full max-w-[70vw] mt-[2vw] ml-[10vw] flex items-center justify-between">
          <button
            onClick={() => setCurrentStep(4)}
            className={`bg-gray-300 text-3vw md:text-vw hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded `}
          >
            back
          </button>
          <button
            onClick={handleButtonClick}
            className={`bg-gray-300 text-3vw md:text-vw hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded `}
          >
            next
          </button>
        </div>
      )}
    </main>
  );
};
