import React from "react";
import ReportCard from "../../Component/Cards/ReportCard";
import { useGlobalContext } from "../../UserContext/UserContext";

const SubChildCategory = () => {
  const { subChildCategoryDetails, setCurrentStep, currentStep, prevForm, setSpecificFaults, nextForm,
   maintenanceDetails, setmaintenanceDetails,
  } = useGlobalContext();
  return (
    <>
      <article className="w-full  max-w-[80vw] bg-gray-100 p-[1vw] md:max-h-[30vw]  overflow-y-scroll grid grid-cols-2 md:grid-cols-6">
        {subChildCategoryDetails?.map((item, index) => (
          <div
            key={index}
            className="transition duration-700 ease-in-out"
          >
            {
              <ReportCard
                key={index}
                icon={item?.icon}
                title={item?.title}
                onMainCategoryClick={() => {
                  setCurrentStep(4);
                  setSpecificFaults(item?.specificFault);
                  setmaintenanceDetails({
                    ...maintenanceDetails,
                    subChildFault: item?.title,
                  });
                }}
              />
            }
          </div>
        ))}
      </article>
      <section className="w-full max-w-[70vw] flex items-center justify-between mt-[7vw] md:mt-vw">
        <button
          disabled={currentStep == 1}
          onClick={prevForm}
          className="md:p-[0.7vw] p-[1.5vw] rounded-md bg-[#152347] hover:bg-[#152347f1] text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw]"
        >
          Back
        </button>
        <button
          disabled={maintenanceDetails.subChildFault === ""}
          onClick={nextForm}
          className={`md:p-[0.7vw] p-[1.5vw] rounded-md bg-amber-500 hover:bg-amber-600 text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw] ${
            maintenanceDetails.subChildFault === ""
              ? "bg-gray-400 hover:bg-gray-400 text-gray-600 cursor-not-allowed"
              : "bg-amber-500 hover:bg-amber-600 text-white"
          }`}
        >
          Next
        </button>
      </section>
    </>
  );
};

export default SubChildCategory;
