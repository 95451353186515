import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import { useInView } from "react-intersection-observer";
const BlogsCard = ({ img, title, info, avatar, name, keywords }) => {
  // const { ref, inView } = useInView({ threshold: 0.6 });

  return (
    <>
      <section className="shadow-xl overflow-hidden transition-transform duration-300 h-[55vw] md:h-[25vw] transform hover:scale-105 md:w-full w-[70vw] xl:max-w-[25vw] lg:max-w-[25vw] md:max-w-[25vw] rounded-md m-vw ">
        <figure className="w-full h-[30vw] md:h-[13vw]">
          <img
            src={img}
            alt="title img"
            className="w-full object-cover h-full"
            onError={(e) => (e.target.src = "/images/no_preview.webp")}
          />
        </figure>

        <div className="p-[2vw] xl:p-[1vw] lg:p-[1vw] md:p-[1vw]">
          <h1 className="text-[#152347] text-[2.7vw] xl:text-[1vw] lg:text-[1vw] md:text-[1vw] font-medium">
            {title}
          </h1>
          <p className='text-[#152347] text-[2.5vw] xl"text-[0.8vw] lg:text-[0.8vw] md:text-[0.8vw] mt-[1vw]'>
            {info?.substring(0, 100)}...
          </p>
          <div className="bg-salt-200 flex w-full justify-start items-center mt-[1.5vw]">
            <img
              src={img ? img : "/images/blog1.jpg"}
              alt="avatar" onError={(e) => (e.target.src = "/images/no_preview.webp")}
              className="w-[5vw] h-[5vw] xl:w-3vw xl:h-3vw lg:w-3vw lg:h-3vw md:w-3vw md:h-3vw rounded-full"
            />
            <h1 className="text-[#152347] ml-vw w-[10vw] text-[1.7vw] xl:text-[1vw] lg:text-[1vw] md:text-[1vw] font-semibold">
              {name}
            </h1>
            <Link to={`/blogs/${keywords}`}>
              <button className="text-[#152347] w-full bg-amber-400 text-[2.5vw] xl:text-[1vw] lg:text-[1vw] md:text-[1vw] ml-[1vw] hover:cursor-pointer hover:bg-[#152347] hover:text-white p-[0.5vw] rounded-md ">
                Read more....
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsCard;
