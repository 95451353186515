import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import Statusbar from "../../Component/Report/Statusbar";
import { useGlobalContext } from "../../UserContext/UserContext";

const ReportDetails = () => {
  const { currentStep, renderSteps, setCurrentStep } = useGlobalContext();
  useEffect(() => {
    setCurrentStep(1);
  }, []);
  return (
    <Layout>
      <main className="bg-gray-100 w-full p-[2vw]">
        <section className="w-full flex flex-col items-center">
          <h1 className="text-[4.3vw] md:text-[2.3vw] font-bold text-gray-600 italic text-center mt-[0.5vw]">
            Submit your maintenance request here
          </h1>
          <Statusbar stage={currentStep} title={"Report Details"} />
        </section>
        <section className="w-full col-center">
          {renderSteps(currentStep)}
        </section>
      </main>
    </Layout>
  );
};

export default ReportDetails;
