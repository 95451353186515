import React from 'react'
import Layout from '../../Layout/Layout'
import LettingProperty from '../../Component/PropertyDetails/LettingProperty'
import { useParams } from 'react-router-dom'
import { useGetLettingsByIdQuery, useGetSalesByIdQuery } from '../../store/storeApi'
import Property from '../../Component/PropertyDetails/Property'

const Email = () => {
    const { id: lettingsId } = useParams();
    const {id: salesId} = useParams();
    const { data: propertyDetails, } = useGetLettingsByIdQuery(lettingsId);
    const { data: salesPropertyDetails } = useGetSalesByIdQuery(salesId);
  return (
    <Layout>
        <main className='w-full flex flex-col md:flex-row p-2vw bg-gray-100' >
        <div className="w-full bg-slate-100 md:max-w-[60vw]">
        <iframe
          src="https://link.agent-online.co.uk/widget/form/Aspo1MXcMSVMxHSeQvAa"
          className='w-full h-screen xl:h-[40vw] lg:h-[50vw] md:h-[50vw]'
          id="inline-Aspo1MXcMSVMxHSeQvAa" 
          data-layout="{'id':'INLINE'}"
          data-trigger-type="alwaysShow"
          data-trigger-value=""
          data-activation-type="alwaysActivated"
          data-activation-value=""
          data-deactivation-type="neverDeactivate"
          data-deactivation-value=""
          data-form-name="Email form"
          data-height="567"
          data-layout-iframe-id="inline-Aspo1MXcMSVMxHSeQvAa"
          data-form-id="Aspo1MXcMSVMxHSeQvAa"
          title="Email form"
        />
      <script src="https://link.agent-online.co.uk/js/form_embed.js"></script>
      </div>
           {propertyDetails ? <section className='ml-2vw mt-4vw'>
            <LettingProperty key={propertyDetails?._id} id={propertyDetails?._id}
                 quantity={propertyDetails?.bedrooms} sharedHouse={propertyDetails?.sharedHouse}
                 weekPrice={propertyDetails?.pricePerWeek} monthPrice={propertyDetails?.pricePerMonth} location={propertyDetails?.propertyName}
                 bedRooms={propertyDetails?.bedrooms}  available={propertyDetails?.availableDate} furnished={propertyDetails?.furnished} bills={propertyDetails?.bills}
                 img={propertyDetails?.image1?.url  || `${process.env.REACT_APP_API_URL}/lettings/${propertyDetails?.image1?.fileName}`} 
                 />
              
            </section> : 
            <section className='ml-2vw mt-4vw'>
             <Property key={salesPropertyDetails?._id} tag={salesPropertyDetails?.tag} icon={salesPropertyDetails?.icon} quantity={salesPropertyDetails?.bedrooms} price={salesPropertyDetails?.price} name={salesPropertyDetails?.propertyName} icon2={salesPropertyDetails?.pics?.[0]?.icon} bed_rooms={salesPropertyDetails?.bedrooms} img={salesPropertyDetails?.image1?.url} img2={salesPropertyDetails?.image2?.url} img3={salesPropertyDetails?.image3?.url} img4={salesPropertyDetails?.image4?.url} img5={salesPropertyDetails?.image5?.url} />
             </section>
            }
        </main>
    </Layout>
  )
}

export default Email