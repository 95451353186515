import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import PageAddress from '../../Component/Common/PageAddress'
import { home_services,lettings_para1, lettings_para2, lettings_para3, partnerBanner } from '../../Data'
import { Link } from 'react-router-dom'
import LettingProperty from '../../Component/PropertyDetails/LettingProperty'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../../Component/Cards/Card'
import RatingsContainer from '../../Component/RatingsContainer'
import { useGetAllLettingsQuery } from '../../store/storeApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import RentSearchFilter from '../../Component/BuyAndRent/RentSearchFilter'
import ListInfoContainer2 from '../../Component/TextAndImageContainer/ListInfoContainer2'
import ListInfoContainer from '../../Component/TextAndImageContainer/ListInfoContainer'
import { Helmet } from 'react-helmet'

const Lettings = () => {
  const [showRentSection] = useState(true)
  const {data:lettingsProperties} = useGetAllLettingsQuery()
  const CustomPrevArrow = (props) => (
    <span {...props} className="text-vw text-black absolute cursor-pointer top-[18vw] xl:top-[14vw] lg:top-[14vw] md:top-[14vw] -left-0.5vw z-50">
    <FontAwesomeIcon icon={faArrowLeft} className='text-2vw xl:text-vw lg:text-vw md:text-vw text-white rounded-full hover:bg-yellow-500 bg-yellow-600 p-[1.3vw] xl:p-[0.7vw] lg:p-[0.7vw] md:p-[0.7vw] text-center' />
  </span>);
  const CustomNextArrow = (props) => (
    <span {...props} className="text-vw text-black absolute cursor-pointer top-[18vw] xl:top-[14vw] lg:top-[14vw] md:top-[14vw] -right-0.5vw xl:right-vw ">
    <FontAwesomeIcon icon={faArrowRight} className='text-2vw xl:text-vw lg:text-vw md:text-vw text-white rounded-full hover:bg-yellow-500 bg-yellow-600 p-[1.3vw] xl:p-[0.7vw] lg:p-[0.7vw] md:p-[0.7vw] text-center' />
  </span> );
  
  const settings2 = { prevArrow: <CustomPrevArrow />, nextArrow: <CustomNextArrow />, arrows: true, slidesToShow: 4, slidesToScroll: 1,
      responsive: [
      {breakpoint: 1024, // Large screens
        settings: { slidesToShow: 4, slidesToScroll: 1, },
      },
      { breakpoint: 768, // Medium screens
         settings: { slidesToShow: 1, slidesToScroll: 1, },
      },
      {breakpoint: 480, // Small screens
         settings: { slidesToShow: 1, slidesToScroll: 1,},
      },
    ],
  };
  // useEffect(() => {
  //   document.title = "Premier Lettings Agency: Expert Services for Property Owners";
  //   document.querySelector('meta[name="description"]').setAttribute("content", "Looking for a reliable lettings agency? Our services cater to property owners seeking professional management and tenant placement.");
  // }, []);

  return (
    <div>
      <Layout>
      <Helmet>
  <meta property="og:title" content="Premier Lettings Agency: Expert Services for Property Owners" />
  <meta property="og:description" content="Looking for a reliable lettings agency? Our services cater to property owners seeking professional management and tenant placement." />
  <meta property="og:image" content="https://danhamz.co.uk/images/Lettings.webp" />
  <meta property="og:site_name" content="Danhamz" />
  <meta property="og:url" content={window.location.href} />
  <meta property="og:type" content="article" />
  {/* <!-- Twitter Card Tags --> */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@Danhamz" />
  <meta name="twitter:creator" content="@Danhamz" />
  <meta name="twitter:title" content="Premier Lettings Agency: Expert Services for Property Owners" />
  <meta name="twitter:description" content="Looking for a reliable lettings agency? Our services cater to property owners seeking professional management and tenant placement." />
  <meta name="twitter:image" content="https://danhamz.co.uk/images/Lettings.webp" />
</Helmet>
      <PageAddress main="Home" mainLink="/" category="Lettings" />
      <figure className='w-full h-[40vw] md:h-full'>
        <img src="images/lettings1.jpeg" alt="remote" className='w-full h-full md:object-cover' />
      </figure>
     <main className="grid grid-cols-1 w-full xl:grid-cols-2 place-items-start place-content-around lg:grid-cols-2 md:grid-cols-2 gap-vw bg-gray-100 p-2vw">
        <article className='flex flex-col mt-[4vw] justify-center items-start w-full'>
          {showRentSection && <RentSearchFilter />}
        </article>
     <figure className='w-full px-[4vw] md:px-0 md:max-w-[40vw] mt-4vw xl:mt-4vw ml-0 xl:ml-4vw lg:ml-4vw md:ml-3vw h-[30vw]'>
      <img src="images/lettings2.jpeg" alt="remote" className='w-full object-cover h-[45vw] md:h-[30vw]' />
     </figure>
     </main>
      <article className="w-full pt-[15vw] md:pt-0 bg-[#fff]">
        {lettings_para1?.map((item, index) => (
          <ListInfoContainer2 key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
        <div className="flex flex-col justify-center bg-gray-200 items-center w-full p-3vw">
            <h1 className='text-yellow-600 text-[4.5vw] xl:text-2vw lg:text-2vw md:text-2vw font-medium capitalize'>Latest Properties to Let</h1>
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 gap-2 mt-2vw w-full">
              {lettingsProperties?.slice(0, 8)?.map((item, index) => (
             <Link to={`/details/${item?._id}`} key={index} className='w-full'>
             <LettingProperty key={index} id={item?._id}
              quantity={item?.bedrooms} sharedHouse={item?.shareHouse}
              weekPrice={item?.pricePerWeek} monthPrice={item?.pricePerMonth} location={item?.propertyName}
              bedRooms={item?.bedrooms}  available={item?.availableDate} furnished={item?.furnished} bills={item?.bills}
            img={item?.image1?.url  || `${process.env.REACT_APP_API_URL}/lettings/${item?.image1?.fileName}`} 
              />
             </Link> 
              ))}
            </div>
          </div>
          <article className="w-full bg-[#fff]">
        {lettings_para2?.map((item, index) => (
          <ListInfoContainer key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
            <section className='col-center bg-white p-3vw'>
            <h1 className='text-yellow-600 text-[4.5vw] xl:text-2vw lg:text-2vw md:text-2vw font-semibold'>Merketing partners</h1>
            <div className="flex mt-2vw justify-evenly items-center">
            {partnerBanner?.map((item, index) => (
                <img key={index} loading='lazy' src={item?.img} alt={index} className='w-[12vw] xl:w-8vw lg:w-8vw md:w-8vw object-cover ml-4vw'/>
            ))}
            </div>
        </section>
        <article className="w-full bg-[#fff]">
        {lettings_para3?.map((item, index) => (
          <ListInfoContainer2 key={index} lists={item?.lists} lists_style={item?.lists_style} lists_color={item?.lists_color} img={item?.img} info={item?.info} icon={item?.icon} title={item?.title} title_color={item?.title_color} info_color={item?.info_color} btn_bg={item?.btn_bg} btn_text={item?.btn_text}  />
        ))}
      </article>
      <article className="col-center bg-gray-200 w-full p-3vw">
          <h1 className='text-yellow-600 text-center text-[4.5vw] xl:text-2vw lg:text-2vw md:text-2vw font-semibold'>Our Services</h1>            
             <div className="grid grid-cols-1 mt-2vw w-full cursor-pointer">
            <Slider {...settings2}>
              {home_services?.map((item, index) => (
                <Link to='/coming-soon' key={index} className='w-full'>
                <Card para={item?.para} img={item?.img} title={item?.title} height={item?.height} />
             </Link>                          
              ))}
            </Slider>
          </div>
          </article>
      <article className='w-full'>
        <RatingsContainer />
      </article>
      </Layout>
    </div>
  )
}

export default Lettings