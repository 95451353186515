import React from "react";
import { repaiReport } from "../../Data";
import ReportCard from "../../Component/Cards/ReportCard";
import { useGlobalContext } from "../../UserContext/UserContext";

const AllCategories = () => {
  const {
    setSubCategoryDetails, currentStep, setCurrentStep, nextForm, prevForm,
    setSpecificFaults, maintenanceDetails, setmaintenanceDetails,
  } = useGlobalContext();
  const handleCategoryChange = (id) => {
    const checkFurtherCategory = repaiReport?.filter((item) => item?.id === id);
    if (checkFurtherCategory?.[0]?.childFault) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(4);
    }
  };
  console.log(maintenanceDetails);
  return (
    <>
      <article className="w-full  max-w-[80vw] bg-gray-100 p-[1vw] md:max-h-[30vw]  overflow-y-scroll grid grid-cols-2 md:grid-cols-6 ">
        {repaiReport?.map((item, index) => (
          <div
            key={index}
            className="transition duration-700 ease-in-out md:ml-0"
          >
            {
              <ReportCard
                key={index}
                icon={item?.icon}
                title={item?.title}
                onMainCategoryClick={() => {
                  handleCategoryChange(item?.id);
                  setSubCategoryDetails(item?.childFault);
                  setSpecificFaults(item?.specificFault);
                  setmaintenanceDetails({ ...maintenanceDetails, mainFault: item.title });
                }}
              />
            }
          </div>
        ))}
      </article>
      <section className="w-full max-w-[70vw] flex items-center justify-between mt-[7vw] md:mt-vw">
        <button
          disabled={currentStep == 1}
          onClick={prevForm}
          className="md:p-[0.7vw] p-[1.5vw] rounded-md bg-[#152347] hover:bg-[#152347f1] text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw]"
        >
          Back
        </button>
        <button
          disabled={maintenanceDetails.mainFault === ""}
          onClick={nextForm}
          className={`md:p-[0.7vw] p-[1.5vw] rounded-md bg-amber-500 hover:bg-amber-600 text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw] ${
            maintenanceDetails.mainFault === ""
              ? "bg-gray-400 hover:bg-gray-400 text-gray-600 cursor-not-allowed" // Apply this class when disabled
              : "bg-amber-500 hover:bg-amber-600 text-white"
          }`}
        >
          Next
        </button>
      </section>
    </>
  );
};

export default AllCategories;
