import React from 'react'
import Layout from '../../Layout/Layout'
import PageAddress from '../../Component/Common/PageAddress'
import { blockManagement1, blockManagement2, blockManagement3, blockManagement4, blockManagement5 } from '../../Data'
import ListInfoContainer from '../../Component/TextAndImageContainer/ListInfoContainer'
import ListInfoContainer2 from '../../Component/TextAndImageContainer/ListInfoContainer2'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import RatingsContainer from '../../Component/RatingsContainer'
import ContentImage from '../../Component/TextAndImageContainer/ContentImage'

const BlockManagement = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      <Layout>
      <PageAddress main='Home' category='Danhamz Management' subCategory='Block Management' mainLink='/' /> 
      <ContentImage img={"/images/block1.jpg"} title={"Block Management"} title_color={"text-amber-500"} button={"Contact Us"}  btn_text={"text-white"} btn_bg={"bg-amber-500"} url={"/contact"} />
       <article className="w-full pt-[10vw] md:pt-[3vw] bg-white">
        {blockManagement1?.map((item, index) => (
          isMobile 
            ? <ListInfoContainer {...item} key={index} />
            : <ListInfoContainer2 {...item} key={index} />
        ))}
      </article>
      <article className="w-full bg-gray-100">
        {blockManagement2?.map((item, index) => (
          <ListInfoContainer {...item} key={index} />
        ))}
      </article>
      <article className="w-full bg-white">
        {blockManagement3?.map((item, index) => (
          isMobile 
            ? <ListInfoContainer {...item} key={index} />
            : <ListInfoContainer2 {...item} key={index} />
        ))}
      </article>
      <article className="w-full bg-gray-100">
        {blockManagement4?.map((item, index) => (
          <ListInfoContainer {...item} key={index} />
        ))}
      </article>
      <article className="w-full bg-white">
        {blockManagement5?.map((item, index) => (
          isMobile 
            ? <ListInfoContainer {...item} key={index} />
            : <ListInfoContainer2 {...item} key={index} />
        ))}
      </article>
      <div className="w-full bg-slate-100">
        <iframe
          src="https://link.agent-online.co.uk/widget/form/Aspo1MXcMSVMxHSeQvAa"
          className='w-full h-screen xl:h-[40vw] lg:h-[50vw] md:h-[50vw]'
          id="inline-Aspo1MXcMSVMxHSeQvAa" 
          data-layout="{'id':'INLINE'}"
          data-trigger-type="alwaysShow"
          data-trigger-value=""
          data-activation-type="alwaysActivated"
          data-activation-value=""
          data-deactivation-type="neverDeactivate"
          data-deactivation-value=""
          data-form-name="Email form"
          data-height="567"
          data-layout-iframe-id="inline-Aspo1MXcMSVMxHSeQvAa"
          data-form-id="Aspo1MXcMSVMxHSeQvAa"
          title="Email form"
        />
      <script src="https://link.agent-online.co.uk/js/form_embed.js"></script>
      </div>
      <div className="w-full">
          <RatingsContainer />
         </div>
      <div className="p-3vw bg-[#152347] col-center">
        <h1 className='text-white text-[3.6vw] md:text-[1.6vw] font-medium text-center w-full max-w-[80vw] md:max-w-[100%]'>
        To find out more, speak to our friendly team on 0113 204 2900
        </h1>
        <Link to='/contact' className='mt-2vw text-[2.5vw] md:text-vw  text-center p-[0.7vw] hover:bg-amber-500 rounded-sm w-full max-w-[12vw] bg-amber-400 text-white'>Email Us</Link>
      </div>
      </Layout>
    </div>
  )
}

export default BlockManagement