import React, { useState } from "react";
import { useGlobalContext } from "../../UserContext/UserContext";

const SelectFault = () => {
  const [selectedFault, setSelectedFault] = useState(null);
  const { prevForm, specificFaults, nextForm, maintenanceDetails, setmaintenanceDetails } = useGlobalContext();

  return (
    <>
      <section className="w-full p-[2vw] mt-[3vw] md:mt-0 max-w-[70vw]">
        {specificFaults?.map((fault, index) => (
          <p key={index}
            className={`text-gray-700 text-[3vw] capitalize md:text-vw md:p-[0.7vw] p-[1.2vw] border-[0.7px] border-gray-600 cursor-pointer
              ${
                selectedFault === fault?.title
                  ? "bg-gray-400 text-white"
                  : "hover:bg-gray-400 hover:text-white"
              }`}
            onClick={() => {
              setmaintenanceDetails({ ...maintenanceDetails, fault: fault?.title });
              setSelectedFault(fault?.title); // Update selected fault
            }}
          >
            {fault?.title}
          </p>
        ))}
        <section className="flex flex-col gap-[1.5vw] mt-[7vw] md:mt-3vw">
          <label>Fault detail *</label>
          <form>
            <textarea
              name="addFault"
              id=""
              placeholder="Fault detail"
              rows="5"
              className="p-[1.5vw] focus:outline-none focus:shadow-md md:p-[0.5vw] rounded-[0.7vw] w-full max-w-[100vw] border-solid border-[1px] border-gray-300 focus:none"
              onChange={(e) =>
                setmaintenanceDetails({
                  ...maintenanceDetails,
                  desc: e.target.value,
                })
              }
            ></textarea>
          </form>
        </section>
      </section>
      <section className="w-full max-w-[70vw] flex items-center justify-between mt-[7vw] md:mt-vw">
        <button
          onClick={prevForm}
          className="md:p-[0.7vw] p-[1.5vw] rounded-md bg-[#152347] hover:bg-[#152347f1] text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw]"
        >
          Back
        </button>
        <button
          onClick={nextForm}
          className={`md:p-[0.7vw] p-[1.5vw] rounded-md bg-amber-500 hover:bg-amber-600 text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw] ${
            maintenanceDetails.fault === ""
              ? "bg-gray-400 hover:bg-gray-400 text-gray-600 cursor-not-allowed"
              : "bg-amber-500 hover:bg-amber-600 text-white"
          }`}
          disabled={maintenanceDetails.fault === ""}
        >
          Next
        </button>
      </section>
    </>
  );
};

export default SelectFault;
