import React from "react";
import ReportCard from "../../Component/Cards/ReportCard";
import { useGlobalContext } from "../../UserContext/UserContext";

const SubCategory = () => {
  const { subCategoryDetails, setSpecificFaults,  setSubChildCategoryDetails,  nextForm,
    prevForm, currentStep, setCurrentStep, maintenanceDetails, setmaintenanceDetails,
  } = useGlobalContext();
  const handleCategoryChange = (id) => {
    const checkFurtherCategory = subCategoryDetails?.filter(
      (item) => item?.id === id
    );
    if (checkFurtherCategory?.[0]?.subChildFault) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(4);
    }
  };
  return (
    <>
      <article className="w-full  max-w-[80vw] bg-gray-100 p-[1vw] md:max-h-[30vw]  overflow-y-scroll grid grid-cols-2 md:grid-cols-6">
        {subCategoryDetails?.map((item, index) => (
          <div
            key={index}
            className="transition duration-700 ease-in-out"
          >
            {
              <ReportCard
                key={index}
                icon={item?.icon}
                title={item?.title}
                onMainCategoryClick={() => {
                  handleCategoryChange(item?.id);
                  setSubChildCategoryDetails(item?.subChildFault);
                  setSpecificFaults(item?.specificFault);
                  setmaintenanceDetails({
                    ...maintenanceDetails,
                    childFault: item?.title,
                  });
                }}
              />
            }
          </div>
        ))}
      </article>
      <section className="w-full max-w-[70vw] flex items-center justify-between mt-[7vw] md:mt-vw">
        <button
          onClick={prevForm}
          className="md:p-[0.7vw] p-[1.5vw] rounded-md bg-[#152347] hover:bg-[#152347f1] text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw]"
        >
          Back
        </button>
        <button
          disabled={maintenanceDetails.childFault === ""}
          onClick={nextForm}
          className={`md:p-[0.7vw] p-[1.5vw] rounded-md bg-amber-500 hover:bg-amber-600 text-white md:text-vw text-[2.5vw] font-medium text-center w-full max-w-[20vw] md:max-w-[5vw]  ${
            maintenanceDetails.childFault === ""
              ? "bg-gray-400 hover:bg-gray-400 text-gray-600 cursor-not-allowed"
              : "bg-amber-500 hover:bg-amber-600 text-white"
          }`}
        >
          Next
        </button>
      </section>
    </>
  );
};

export default SubCategory;
