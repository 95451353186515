import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import BlogsCard from "../../Component/Cards/BlogsCard";
import { useGlobalContext } from "../../UserContext/UserContext";
import axios from "axios";
import { Toaster } from "sonner";
import { Link } from "react-router-dom";
import Loader from "../Reports/Loader";

const Blogs = () => {
  const [blogsPosts, setBlogsPosts] = useState([]);
  const token = JSON.parse(localStorage.getItem("userLoginInfo"));
  const admin = token?.admin ? token?.admin : "user";
  const { newBlog } = useGlobalContext();

  const getBlogData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/readBlogs`);
      setBlogsPosts(res.data);
    } catch (err) {
    }
  };

  useEffect(() => {
    // setAdmin(true);
    getBlogData();
  }, [newBlog]);

  return (
    <Layout>
      <Toaster />
      <main className="bg-white pt-2vw">
     { admin && admin === 'admin' && <Link to="/create-blog">
        <button className="ml-[3vw] hover:shadow-lg bg-[#152347] text-white text-[1vw] rounded-md shadow-md p-[0.5vw]" >
         create
        </button>
     </Link>}

        <h1 className="text-[2vw] text-amber-500 font-semibold underline text-bold text-center">
          Blogs
        </h1>
        <p className="text-[1.5vw] text-center xl:text-[1vw] lg:text-[1vw] md:text-[1vw]text-center mt-[1vw]">
          All you want to know about our landlords and tenants
        </p>
        {blogsPosts.length === 0 && <main className="text-center flex justify-center items-center w-screen"><Loader /></main>}

        <div
          className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 place-content-center place-items-center  w-full p-[2vw] hover:cursor-pointer transition-all duration-300 hover:scale-2 "
          data-aos="fade-up"
        >
          {blogsPosts.map((item, index) => (
            <div key={index}>
              <BlogsCard key={item._id} keywords={item?.keywords} info={item?.info1} img={`${process.env.REACT_APP_API_URL}/blogsPosts/${item?.image1?.fileName}`} {...item} />
            </div>
          ))}
        </div>
      </main>
    </Layout>
  );
};

export default Blogs;
